import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Page } from 'src/components';


const ProtectedAreasHome = () => {
  const history = useHistory();
  useEffect(() => {
    history.replace('/app/protected-areas/load-project');
  }, [ history ]);

  return (
    <Page title="Areas protegidas">
      Trabajo en progreso...
    </Page>
  );
};


export { ProtectedAreasHome };
