import React, { memo, useCallback } from 'react';
import { Grid, Button, Typography, Box, Accordion, AccordionSummary, AccordionDetails, TextField,
  FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';

import * as commons from 'src/scenes/subformCommons/subformCommons';
import { TypographicInputLabel, InfoTooltip } from 'src/components';



const useStyles = makeStyles(theme => ({
  ...commons.styles(theme),
}));

const accordionTransitionPropObject = { unmountOnExit: true };

const ObservationPointsForm = memo(({ setHighlightedFeat, errors, index, setForm, observationPoint, viewsheds }) => {
  const { featId, finished, dataOnWeb } = observationPoint;

  const observationPointsSelected = Array.from(new Set(viewsheds.flatMap(viewshed => viewshed.observationPoints)));
  const pointIsSelected = observationPointsSelected.includes(featId);

  const stopEventPropagation = event => event.stopPropagation();

  const toggleHighlight = useCallback(() => {
    setHighlightedFeat({ featId });
  }, [ setHighlightedFeat, featId ]);

  const classes = useStyles();

  const accordionSummaryClass = dataOnWeb && finished ? classes.accordionOnWebFinished
    : finished ? classes.accordionFinished
    : classes.accordionSummary;

  const onChange = useCallback(e => {
    const fieldName = e.target.name;
    const data = e.target.value;
    setForm(pf => ({
      ...pf,
      observationPoints: pf.observationPoints.map((op, currInd) => index === currInd ? ({ ...op, [fieldName]: data }) : op),
    }));
  // eslint-disable-next-line -- como setForm viene de un useState, no necesita incluirse aquí
  }, [ index ]);

  const onCheckboxChange = useCallback(e => {
    setForm(pf => ({
      ...pf,
      observationPoints: pf.observationPoints.map((op, currInd) => index === currInd ? ({ ...op, [e.target.name]: e.target.checked }) : op),
    }));
  // eslint-disable-next-line
  }, [ index ]);

  return <Accordion TransitionProps={accordionTransitionPropObject}>
    <AccordionSummary className={ accordionSummaryClass } expandIcon={<ExpandMoreIcon />}>
      <Box display='flex' flexGrow={ 1 } alignItems='center' justifyContent='space-between'>
        <Box display='flex' alignItems='center'>
          { Boolean(errors) &&
            <Box component="span" mr={1} color="error.main">
              ¡Problema al validar datos!
            </Box>
          }
          <Typography>
            Punto de observación #{index + 1}{`: ${observationPoint.name || ''}`}
          </Typography>
        </Box>
        <Box>
          <Box onClick={stopEventPropagation} display="inline" mr={4}>
            { pointIsSelected ?
              <InfoTooltip contents='Punto de observación utilizado en cuenca visual.'>
                <FormControlLabel labelPlacement="start" label="¿Finalizado?" className={classes.checkLabel}
                  control={ <Checkbox color="primary" checked={finished}
                    onChange={onCheckboxChange} name='finished'
                    disabled={pointIsSelected}
                  />
                  }
                />
              </InfoTooltip> :
              <FormControlLabel labelPlacement="start" label="¿Finalizado?" className={classes.checkLabel}
                control={ <Checkbox color="primary" checked={finished}
                  onChange={onCheckboxChange} name='finished'
                  disabled={pointIsSelected}
                />
                }
              />
            }
          </Box>
          <Box onClick={stopEventPropagation} display="inline">
            <Button variant="contained" color="secondary" onClick={ toggleHighlight }>
              Des/marcar en mapa
            </Button>
          </Box>
        </Box>
      </Box>
    </AccordionSummary>
    <AccordionDetails className={classes.accordionDetails}>
      <Grid container direction="row" spacing={2}>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-name`}>Nombre</TypographicInputLabel>
          <TextField value={observationPoint.name || ''} name="name" id={`${featId}-name`} variant="outlined"
            size="small" onChange={onChange} fullWidth autoComplete="off" />
        </Grid>

        <Grid item xs={12}>
          <Box my={1}>
            <Box>
              <TypographicInputLabel htmlFor={`${featId}-locality`}>Localidad</TypographicInputLabel>
              <TextField
                id={`${featId}-locality`}
                className={classes.textArea}
                minRows={1}
                maxRows={4}
                multiline
                name="locality"
                onChange={onChange}
                type="text"
                value={observationPoint.locality}
                variant="outlined"
              />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box my={1}>
            <Box>
              <TypographicInputLabel htmlFor={`${featId}-method-description`}>Descripción</TypographicInputLabel>
              <TextField
                id={`${featId}-description`}
                className={classes.textArea}
                minRows={1}
                maxRows={4}
                multiline
                name="description"
                onChange={onChange}
                helperText={`Breve descripción del punto de observación o del entorno`}
                type="text"
                value={observationPoint.description}
                variant="outlined"
              />
            </Box>
          </Box>
        </Grid>

      </Grid>
    </AccordionDetails>
  </Accordion>;
});

ObservationPointsForm.propTypes = {
  setHighlightedFeat: PropTypes.func,
  errors: PropTypes.object,
  index: PropTypes.number,
  setForm: PropTypes.func,
  observationPoint: PropTypes.object,
  viewsheds: PropTypes.array,
};

ObservationPointsForm.displayName = 'ObservationPointsForm';


export { ObservationPointsForm };