import React, { memo, useCallback, useState } from 'react';
import { Grid, Button, Typography, Box, Accordion, AccordionSummary, AccordionDetails, TextField,
  FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';

import * as commons from 'src/scenes/subformCommons/subformCommons';
import { TypographicInputLabel } from 'src/components';
import { FaunaRecordForm } from 'src/scenes/Fauna/components';
import { parseNumber, testDecimalsFormat } from 'src/utils';


const useStyles = makeStyles(theme => ({
  ...commons.styles(theme),
}));

const accordionTransitionPropObject = { unmountOnExit: true };

const SamplingStationForm = memo(({ samplingStation, errors, index, setForm, setHighlightedFeat, campaigns,
  createSpeciesRegister, originOptions, openDeleteRecord }) => {

  const { featId, finished, dataOnWeb, stationType, records } = samplingStation;

  const [ recordsExpanded, setRecordsExpanded ] = useState(false);

  const containsScientificName = useCallback(scientificName => {
    const scientificNameSet = {};
    records.forEach(record => {
      if (record.scientificName) {
        scientificNameSet[record.scientificName] = scientificNameSet[record.scientificName] ?
          scientificNameSet[record.scientificName] + 1 :
          1;
      }
    });
    return scientificNameSet[scientificName] ? scientificNameSet[scientificName] > 1 : false;
  }, [ records ]);

  const stopEventPropagation = event => event.stopPropagation();
  const toggleHighlight = useCallback(() => {
    setHighlightedFeat({ featId });
  }, [ setHighlightedFeat, featId ]);

  const classes = useStyles();

  const accordionSummaryClass = dataOnWeb && finished ? classes.accordionOnWebFinished
    : finished ? classes.accordionFinished
    : classes.accordionSummary;

  const onChange = useCallback(e => {
    const fieldName = e.target.name;
    const data = e.target.value;
    setForm(pf => ({
      ...pf,
      [stationType]: pf[stationType].map((ss, currInd) => index === currInd ? ({ ...ss, [fieldName]: data }) : ss),
    }));
  // eslint-disable-next-line -- como setForm viene de un useState, no necesita incluirse aquí
  }, [ index ]);

  const onCheckboxChange = useCallback(e => {
    setForm(pf => ({
      ...pf,
      [stationType]: pf[stationType].map((ss, currInd) =>
        index === currInd ? ({ ...ss, [e.target.name]: e.target.checked }) : ss),
    }));
  // eslint-disable-next-line
  }, [ index ]);

  const addRegister = () => {
    setForm(pf => ({
      ...pf,
      [stationType]: pf[stationType].map((ss, currInd) =>
        index === currInd ? ({ ...ss, records: [ ...pf[stationType][index].records, createSpeciesRegister() ] }) : ss),
    }));
    setRecordsExpanded(true);
  };

  const onBlurNumber = useCallback(e => {
    onChange({ target: { name: e.target.name, value: parseNumber(e.target.value, 0) ?? null } });
  }, [ onChange ]);

  const onChangeNumber = useCallback(e => {
    const data = e.target.value;
    if (!testDecimalsFormat(data, { decimals: 0, wholePart: 6 })) {
      return;
    }
    onChange(e);
  // eslint-disable-next-line -- como setForm viene de un useState, no necesita incluirse aquí
  }, [ index ]);

  const clickRecordsAccordion = () => {
    setRecordsExpanded(!recordsExpanded);
  };

  const onUpdateRecord = useCallback(recordIndex => ({ field, data }) =>
    setForm(pf => ({
      ...pf,
      [stationType]: pf[stationType].map((ss, currInd) =>
        index === currInd ? ({
          ...ss,
          records: pf[stationType][index].records.map((record, i) => i === recordIndex ?
            ({ ...record, [field]: data }) :
            record),
        }) : ss),
    // eslint-disable-next-line -- como setForm viene de un useState, no necesita incluirse aquí
    })), [ stationType, index ]);

  const onDeleteRecord = useCallback((record, recordIndex) => openDeleteRecord(recordIndex, record, stationType, index)
    , [ openDeleteRecord, stationType, index ]);

  return <Accordion TransitionProps={accordionTransitionPropObject}>
    <AccordionSummary className={ accordionSummaryClass } expandIcon={<ExpandMoreIcon />}>
      <Box display='flex' flexGrow={ 1 } alignItems='center' justifyContent='space-between'>
        <Box display='flex' alignItems='center'>
          { Boolean(errors) &&
            <Box component="span" mr={1} color="error.main">
              ¡Problema al validar datos!
            </Box>
          }
          <Typography>
            Estación #{index + 1}{`: ${samplingStation.name || ''}`}
          </Typography>
        </Box>
        <Box>
          <Box onClick={stopEventPropagation} display="inline" mr={4}>
            <FormControlLabel labelPlacement="start" label="¿Finalizado?" className={classes.checkLabel}
              control={ <Checkbox color="primary" checked={finished}
                onChange={onCheckboxChange} name='finished'
              />
              }
            />
          </Box>
          <Box onClick={stopEventPropagation} display="inline">
            <Button variant="contained" color="secondary" onClick={ toggleHighlight }>
              Des/marcar en mapa
            </Button>
          </Box>
        </Box>
      </Box>
    </AccordionSummary>
    <AccordionDetails>
      <Grid container direction="row" spacing={2}>
        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-name`}>Nombre</TypographicInputLabel>
          <TextField value={samplingStation.name || ''} name="name" id={`${featId}-name`} variant="outlined"
            size="small" onChange={onChange} fullWidth autoComplete="off" />
        </Grid>

        <Grid item md={6} xs={6}>
          <Box>
            <TypographicInputLabel htmlFor={`${featId}-campaign-id`}>
              Campaña a la que pertenece
            </TypographicInputLabel>
            <Autocomplete
              multiple
              value={samplingStation.campaignIds}
              name={'campaignIds'}
              options={campaigns.filter(c => c.finished).map(c => c.tempId)}
              getOptionLabel={value => {
                const index = campaigns.findIndex(c => c.tempId === value);
                const c = campaigns[index];
                const campaignName = c.name ? `: ${c.name}` : '';
                return `Campaña #${index + 1}${campaignName}`;
              }}
              noOptionsText="No hay campañas disponibles"
              filterSelectedOptions
              renderInput={params => (
                <TextField
                  {...params}
                  size="small"
                  id={`${featId}-campaign-id`}
                  variant="outlined"
                  placeholder={'Seleccione campaña'}
                  error={Boolean(errors?.campaignIds)}
                  helperText={`Solo se pueden selecionar campañas finalizadas. ${
                    errors?.campaignIds ? errors.campaignIds.errorMessage : ''}`}
                />
              )}
              onChange={(event, newValue) => {
                if (newValue === null) {
                  onChange({ target: { name: 'finished', value: false } });
                }
                onChange({ target: { name: 'campaignIds', value: newValue === null ? null : newValue } });
              }}
            />
          </Box>
        </Grid>

        { stationType === 'aquatic' &&
          <Grid item md={6} xs={6}>
            <TypographicInputLabel htmlFor={`${index}-absolute-frequency`}>Frecuencia absoluta</TypographicInputLabel>
            <TextField value={samplingStation.absoluteFrequency ?? ''} name="absoluteFrequency" id={`${index}-absolute-frequency`}
              variant="outlined" size="small" onBlur={onBlurNumber} fullWidth autoComplete="off"
              onChange={onChangeNumber}
            />
          </Grid>
        }

        <Grid item md={12} xs={12}>
          <Accordion defaultExpanded={false} TransitionProps={accordionTransitionPropObject}
            expanded={samplingStation.records.length > 0 && recordsExpanded} onChange={clickRecordsAccordion}>
            <AccordionSummary className={ classes.accordionSummary } expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5" gutterBottom>Registros <small>({samplingStation.records.length})</small></Typography>
              <Box mx={1.5} onClick={stopEventPropagation}>
                <Button variant="contained" color="secondary" onClick={ addRegister }>
                  Añadir Registro
                </Button>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box width='100%'>
                { samplingStation.records.map((record, recordIndex) =>
                  <FaunaRecordForm record={record} index={recordIndex} key={`${recordIndex}-record`}
                    originOptions={originOptions} recordType={stationType}
                    deleteRecord={onDeleteRecord}
                    onUpdate={onUpdateRecord}
                    containsScientificName={containsScientificName}
                  />,
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </AccordionDetails>
  </Accordion>;
});

SamplingStationForm.propTypes = {
  errors: PropTypes.object,
  index: PropTypes.number,
  setForm: PropTypes.func,
  samplingStation: PropTypes.object,
  setHighlightedFeat: PropTypes.func,
  campaigns: PropTypes.array,
  createSpeciesRegister: PropTypes.func,
  originOptions: PropTypes.object.isRequired,
  openDeleteRecord: PropTypes.func,
};

SamplingStationForm.displayName = 'SamplingStationForm';


export { SamplingStationForm };