import React, { memo, useCallback } from 'react';
import { Grid, Button, Typography, Box, Accordion, AccordionSummary, AccordionDetails, FormControlLabel, Checkbox,
  TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import { KeyboardDatePicker } from '@material-ui/pickers';

import { TypographicInputLabel } from 'src/components';
import { testDecimalsFormat, parseNumber, prependZeros, parseStringDayMonthYearToDate } from 'src/utils';
import * as commons from 'src/scenes/subformCommons/subformCommons';


const useStyles = makeStyles(theme => ({
  ...commons.styles(theme),
}));

const accordionTransitionPropObject = { unmountOnExit: true };

const ContinuousMeasurementsForm = memo(({ setHighlightedFeat, errors, index, setForm, continuousMeasurement }) => {
  const { featId, finished, dataOnWeb } = continuousMeasurement;

  const stopEventPropagation = event => event.stopPropagation();

  const toggleHighlight = useCallback(() => {
    setHighlightedFeat({ featId });
  }, [ setHighlightedFeat, featId ]);

  const classes = useStyles();

  const onChange = useCallback(e => {
    const fieldName = e.target.name;
    const data = e.target.value;
    setForm(pf => ({
      ...pf,
      continuousMeasurements: pf.continuousMeasurements.map((cm, currInd) => index === currInd ? ({ ...cm, [fieldName]: data }) : cm),
    }));
  // eslint-disable-next-line -- como setForm viene de un useState, no necesita incluirse aquí
  }, [ index ]);

  const accordionSummaryClass = dataOnWeb && finished ? classes.accordionOnWebFinished
    : finished ? classes.accordionFinished
    : classes.accordionSummary;

  const onBlurNumber = useCallback(e => {
    setForm(pf => ({
      ...pf,
      continuousMeasurements: pf.continuousMeasurements.map((cm, currInd) =>
        index === currInd ? ({ ...cm, [e.target.name]: parseNumber(cm[e.target.name], 0) ?? null }) : cm,
      ),
    }));
  // eslint-disable-next-line -- como setForm viene de un useState, no necesita incluirse aquí
  }, [ index ]);

  const onCheckboxChange = useCallback(e => {
    setForm(pf => ({
      ...pf,
      continuousMeasurements: pf.continuousMeasurements.map((cm, currInd) =>
        index === currInd ? ({ ...cm, [e.target.name]: e.target.checked }) : cm),
    }));
  // eslint-disable-next-line
  }, [ index ]);

  return <Accordion TransitionProps={accordionTransitionPropObject}>
    <AccordionSummary className={ accordionSummaryClass } expandIcon={<ExpandMoreIcon />}>
      <Box display='flex' flexGrow={ 1 } alignItems='center' justifyContent='space-between'>
        <Box display='flex' alignItems='center'>
          { Boolean(errors) &&
            <Box component="span" mr={1} color="error.main">
              ¡Problema al validar datos!
            </Box>
          }
          <Typography>
            Medición continua #{index + 1}{`: ${continuousMeasurement.name || ''}`}
          </Typography>
        </Box>
        <Box>
          <Box onClick={stopEventPropagation} display="inline" mr={4}>
            <FormControlLabel labelPlacement="start" label="¿Finalizado?" className={classes.checkLabel}
              control={ <Checkbox color="primary" checked={finished}
                onChange={onCheckboxChange} name='finished'
              />
              }
            />
          </Box>
          <Box onClick={stopEventPropagation} display="inline">
            <Button variant="contained" color="secondary" onClick={ toggleHighlight }>
              Des/marcar en mapa
            </Button>
          </Box>
        </Box>
      </Box>
    </AccordionSummary>
    <AccordionDetails className={classes.accordionDetails}>
      <Grid container direction="row" spacing={2}>
        <Grid item md={12} xs={12}>
          <TypographicInputLabel htmlFor={`${featId}-name`}>Nombre</TypographicInputLabel>
          <TextField value={continuousMeasurement.name || ''} name="name" id={`${featId}-name`} variant="outlined"
            size="small" onChange={onChange} fullWidth autoComplete="off" />
        </Grid>

        <Grid item md={6} xs={6}>
          <Box mb={1}>
            <TypographicInputLabel htmlFor={`${featId}-start-date`}>
              Fecha de inicio
            </TypographicInputLabel>
          </Box>
          <Box my={1}>
            <KeyboardDatePicker
              placeholder="Fecha de inicio"
              name={'startDate'}
              value={continuousMeasurement.startDate ? parseStringDayMonthYearToDate(continuousMeasurement.startDate) : null}
              onChange={newValue => {
                if (!newValue) {
                  onChange({ target: { name: 'startDate', value: null } });
                  return;
                }
                const day = newValue.getDate();
                const month = newValue.getMonth() + 1;
                const year = newValue.getFullYear();
                onChange({
                  target: { name: 'startDate', value: newValue === null ? null :
                    `${prependZeros(day)}/${prependZeros(month)}/${year}` },
                });
              }}
              format="dd/MM/yyyy"
              maxDate={new Date()}
              invalidDateMessage="Formato de fecha inválido"
              maxDateMessage="Fecha superior a la de hoy"
            />
          </Box>
        </Grid>

        <Grid item md={6} xs={6}>
          <Box mb={1}>
            <TypographicInputLabel htmlFor={`${featId}-end-date`}>
              Fecha de término
            </TypographicInputLabel>
          </Box>
          <Box my={1}>
            <KeyboardDatePicker
              placeholder="Fecha de término"
              name={'endDate'}
              value={continuousMeasurement.endDate ? parseStringDayMonthYearToDate(continuousMeasurement.endDate) : null}
              onChange={newValue => {
                if (!newValue) {
                  onChange({ target: { name: 'endDate', value: null } });
                  return;
                }
                const day = newValue.getDate();
                const month = newValue.getMonth() + 1;
                const year = newValue.getFullYear();
                onChange({
                  target: { name: 'endDate', value: newValue === null ? null :
                    `${prependZeros(day)}/${prependZeros(month)}/${year}` },
                });
              }}
              format="dd/MM/yyyy"
              maxDate={new Date()}
              invalidDateMessage="Formato de fecha inválido"
              maxDateMessage="Fecha superior a la de hoy"
            />
          </Box>
        </Grid>

        <Grid item md={12} xs={12}>
          <TypographicInputLabel htmlFor={`${featId}-total-days`}>Total de días medidos</TypographicInputLabel>
          <TextField
            placeholder="Total de días medidos"
            variant="outlined" size="small" autoComplete="off"
            name={'totalDays'}
            value={continuousMeasurement.totalDays !== null ? continuousMeasurement.totalDays : ''}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            onChange={e => testDecimalsFormat(e.target.value, { decimals: 0 }) && onChange(e)}
            error={Boolean(errors?.totalDays)}
            helperText={`${errors?.totalDays ? errors.totalDays.errorMessage : ''}`}
            onBlur={onBlurNumber}
            fullWidth
          />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-day-measurement-6-8`}>Medición perido diurno 6-8 m/s [dBa]</TypographicInputLabel>
          <TextField
            placeholder="Medición del nivel ruido de fondo rango de velocidad 6-8 m/s."
            variant="outlined" size="small" autoComplete="off"
            name={'dayMeasurement68'}
            value={continuousMeasurement.dayMeasurement68 !== null ? continuousMeasurement.dayMeasurement68 : ''}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            onChange={e => testDecimalsFormat(e.target.value, { decimals: 0 }) && onChange(e)}
            error={Boolean(errors?.dayMeasurement68)}
            helperText={`${errors?.dayMeasurement68 ? errors.dayMeasurement68.errorMessage : ''}`}
            onBlur={onBlurNumber}
            fullWidth
          />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-day-measurement-8-10`}>Medición perido diurno 8-10 m/s [dBa]</TypographicInputLabel>
          <TextField
            placeholder="Medición del nivel ruido de fondo rango de velocidad 8-10 m/s."
            variant="outlined" size="small" autoComplete="off"
            name={'dayMeasurement810'}
            value={continuousMeasurement.dayMeasurement810 !== null ? continuousMeasurement.dayMeasurement810 : ''}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            onChange={e => testDecimalsFormat(e.target.value, { decimals: 0 }) && onChange(e)}
            error={Boolean(errors?.dayMeasurement810)}
            helperText={`${errors?.dayMeasurement810 ? errors.dayMeasurement810.errorMessage : ''}`}
            onBlur={onBlurNumber}
            fullWidth
          />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-day-measurement10-12`}>Medición perido diurno 10-12 m/s [dBa]</TypographicInputLabel>
          <TextField
            placeholder="Medición del nivel ruido de fondo rango de velocidad 10-12 m/s."
            variant="outlined" size="small" autoComplete="off"
            name={'dayMeasurement1012'}
            value={continuousMeasurement.dayMeasurement1012 !== null ? continuousMeasurement.dayMeasurement1012 : ''}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            onChange={e => testDecimalsFormat(e.target.value, { decimals: 0 }) && onChange(e)}
            error={Boolean(errors?.dayMeasurement1012)}
            helperText={`${errors?.dayMeasurement1012 ? errors.dayMeasurement1012.errorMessage : ''}`}
            onBlur={onBlurNumber}
            fullWidth
          />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-night-measurement-6-8`}>Medición perido nocturno 6-8 m/s [dBa]</TypographicInputLabel>
          <TextField
            placeholder="Medición del nivel ruido de fondo rango de velocidad 6-8 m/s."
            variant="outlined" size="small" autoComplete="off"
            name={'nightMeasurement68'}
            value={continuousMeasurement.nightMeasurement68 !== null ? continuousMeasurement.nightMeasurement68 : ''}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            onChange={e => testDecimalsFormat(e.target.value, { decimals: 0 }) && onChange(e)}
            error={Boolean(errors?.nightMeasurement68)}
            helperText={`${errors?.nightMeasurement68 ? errors.nightMeasurement68.errorMessage : ''}`}
            onBlur={onBlurNumber}
            fullWidth
          />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-night-measurement-8-10`}>
            Medición perido nocturno 8-10 m/s [dBa]
          </TypographicInputLabel>
          <TextField
            placeholder="Medición del nivel ruido de fondo rango de velocidad 8-10 m/s."
            variant="outlined" size="small" autoComplete="off"
            name={'nightMeasurement810'}
            value={continuousMeasurement.nightMeasurement810 !== null ? continuousMeasurement.nightMeasurement810 : ''}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            onChange={e => testDecimalsFormat(e.target.value, { decimals: 0 }) && onChange(e)}
            error={Boolean(errors?.nightMeasurement810)}
            helperText={`${errors?.nightMeasurement810 ? errors.nightMeasurement810.errorMessage : ''}`}
            onBlur={onBlurNumber}
            fullWidth
          />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-night-measurement10-12`}>
            Medición perido nocturno 10-12 m/s [dBa]
          </TypographicInputLabel>
          <TextField
            placeholder="Medición del nivel ruido de fondo rango de velocidad 10-12 m/s."
            variant="outlined" size="small" autoComplete="off"
            name={'nightMeasurement1012'}
            value={continuousMeasurement.nightMeasurement1012 !== null ? continuousMeasurement.nightMeasurement1012 : ''}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            onChange={e => testDecimalsFormat(e.target.value, { decimals: 0 }) && onChange(e)}
            error={Boolean(errors?.nightMeasurement1012)}
            helperText={`${errors?.nightMeasurement1012 ? errors.nightMeasurement1012.errorMessage : ''}`}
            onBlur={onBlurNumber}
            fullWidth
          />
        </Grid>
      </Grid>
    </AccordionDetails>
  </Accordion>;
});

ContinuousMeasurementsForm.propTypes = {
  setHighlightedFeat: PropTypes.func,
  errors: PropTypes.object,
  index: PropTypes.number,
  setForm: PropTypes.func,
  continuousMeasurement: PropTypes.object,
};

ContinuousMeasurementsForm.displayName = 'ContinuousMeasurementsForm';


export { ContinuousMeasurementsForm };