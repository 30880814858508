import React, { memo, useCallback } from 'react';
import { Grid, Button, Typography, Box, Accordion, AccordionSummary, AccordionDetails, TextField,
  FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';

import { TypographicInputLabel } from 'src/components';
import * as commons from 'src/scenes/subformCommons/subformCommons';


const useStyles = makeStyles(theme => ({
  ...commons.styles(theme),
  textArea: {
    width: '100%',
  },
}));

const accordionTransitionPropObject = { unmountOnExit: true };

const getOptionSelected = (option, value) => option.id === value || option === value;

const declaredOptions = [
  { label: 'Sí', value: true },
  { label: 'No', value: false },
];

const HeritageForm = memo(({ setHighlightedFeat, errors, index, setForm, heritage }) => {
  const { featId, finished, dataOnWeb } = heritage;
  const isDeclaredVal = heritage.isDeclared === null ? null :
    heritage.isDeclared ? declaredOptions[0] : declaredOptions[1];

  const stopEventPropagation = event => event.stopPropagation();

  const toggleHighlight = useCallback(() => {
    setHighlightedFeat({ featId });
  }, [ setHighlightedFeat, featId ]);

  const classes = useStyles();

  const onChange = useCallback(e => {
    const fieldName = e.target.name;
    const data = e.target.value;
    setForm(pf => ({
      ...pf,
      heritage: pf.heritage.map((r, currInd) => index === currInd ? ({ ...r, [fieldName]: data }) : r),
    }));
  // eslint-disable-next-line -- como setForm viene de un useState, no necesita incluirse aquí
  }, [ index ]);

  const accordionSummaryClass = dataOnWeb && finished ? classes.accordionOnWebFinished
    : finished ? classes.accordionFinished
    : classes.accordionSummary;

  const onCheckboxChange = useCallback(e => {
    setForm(pf => ({
      ...pf,
      heritage: pf.heritage.map((h, currInd) => index === currInd ? ({ ...h, [e.target.name]: e.target.checked }) : h),
    }));
  // eslint-disable-next-line
  }, [ index ]);

  return <Accordion TransitionProps={accordionTransitionPropObject}>
    <AccordionSummary className={ accordionSummaryClass } expandIcon={<ExpandMoreIcon />}>
      <Box display='flex' flexGrow={ 1 } alignItems='center' justifyContent='space-between'>
        <Box display='flex' alignItems='center'>
          { Boolean(errors) &&
            <Box component="span" mr={1} color="error.main">
              ¡Problema al validar datos!
            </Box>
          }
          <Typography>
            Patrimonio #{index + 1}{`: ${heritage.name || ''}`}
          </Typography>
        </Box>
        <Box>
          <Box onClick={stopEventPropagation} display="inline" mr={4}>
            <FormControlLabel labelPlacement="start" label="¿Finalizado?" className={classes.checkLabel}
              control={ <Checkbox color="primary" checked={finished}
                onChange={onCheckboxChange} name='finished'
              />
              }
            />
          </Box>
          <Box onClick={stopEventPropagation} display="inline">
            <Button variant="contained" color="secondary" onClick={ toggleHighlight }>
              Des/marcar en mapa
            </Button>
          </Box>
        </Box>
      </Box>
    </AccordionSummary>
    <AccordionDetails className={classes.accordionDetails}>
      <Grid container direction="row" spacing={2}>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-name`}>Nombre</TypographicInputLabel>
          <TextField value={heritage.name || ''} name="name" id={`${featId}-name`} variant="outlined"
            size="small" onChange={onChange} fullWidth autoComplete="off" />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-id`}>ID</TypographicInputLabel>
          <TextField value={heritage.idInProject} name="idInProject" id={`${featId}-id`} variant="outlined" size="small"
            onChange={onChange}
            fullWidth
            autoComplete="off" />
        </Grid>

        <Grid item md={12} xs={12}>
          <Box mb={1}>
            <TypographicInputLabel htmlFor={`${featId}-environmental-components`}>
              Elemento es declarado patrimonio con el CMN
            </TypographicInputLabel>
          </Box>
          <Box my={1}>
            <Autocomplete
              value={isDeclaredVal }
              name={'isDeclared'}
              options={declaredOptions}
              getOptionLabel={value => value?.label || ''}
              getOptionSelected={getOptionSelected}
              filterSelectedOptions
              renderInput={params => (
                <TextField
                  {...params}
                  id={`${featId}-is-declared`}
                  variant="outlined"
                  placeholder={heritage.isDeclared ? '' : 'Seleccionar opción'}
                />
              )}
              onChange={(event, newValue) => {
                onChange({ target: { name: 'isDeclared', value: newValue === null ? null : newValue.value } });
              }}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box my={1}>
            <Box>
              <TypographicInputLabel htmlFor={`${featId}-description`}>Descripción</TypographicInputLabel>
              <TextField
                id={`${featId}-description`}
                className={classes.textArea}
                minRows={1}
                maxRows={4}
                multiline
                name="description"
                onChange={onChange}
                helperText={`Breve descripción del elemento`}
                type="text"
                value={heritage.description}
                variant="outlined"
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </AccordionDetails>
  </Accordion>;
});

HeritageForm.propTypes = {
  setHighlightedFeat: PropTypes.func,
  errors: PropTypes.object,
  index: PropTypes.number,
  setForm: PropTypes.func,
  heritage: PropTypes.object,
};

HeritageForm.displayName = 'HeritageForm';


export { HeritageForm };