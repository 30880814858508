import React, { useRef, useState, memo, useEffect, useCallback } from 'react';
import { Grid, Button, Typography, Box, Accordion, AccordionSummary, AccordionDetails, TextField,
  FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import { toast } from 'react-toastify';

import { testDecimalsFormat, parseNumber, generateDebouncer } from 'src/utils';
import { TypographicInputLabel } from 'src/components';
import * as commons from 'src/scenes/subformCommons/subformCommons';
import { protectedAreasApi } from 'src/services';


const useStyles = makeStyles(theme => ({
  ...commons.styles(theme),
  textArea: {
    width: '100%',
  },
}));
const getOptionSelected = (option, value) => option.cod === value || option === value;

const accordionTransitionPropObject = { unmountOnExit: true };

const WetlandForm = memo(({ setHighlightedFeat, errors, index, setForm, wetland }) => {
  const { featId, finished, dataOnWeb } = wetland;

  const stopEventPropagation = event => event.stopPropagation();

  const [ wetlandOptions, setWetlandOptions ] = useState([]);
  const [ wetlandSelected, setWetlandSelected ] = useState();
  const [ isFetchingOptions, setIsFetchingOptions ] = useState(false);

  const getOptionsTimeoutRef = useRef();

  const toggleHighlight = useCallback(() => {
    setHighlightedFeat({ featId });
  }, [ setHighlightedFeat, featId ]);

  const classes = useStyles();

  const onChange = useCallback(e => {
    const fieldName = e.target.name;
    const data = e.target.value;
    setForm(pf => ({
      ...pf,
      wetland: pf.wetland.map((w, currInd) => index === currInd ? ({ ...w, [fieldName]: data }) : w),
    }));
  // eslint-disable-next-line -- como setForm viene de un useState, no necesita incluirse aquí
  }, [ index ]);

  const onBlurNumber = useCallback(e => {
    setForm(pf => ({
      ...pf,
      wetland: pf.wetland.map((w, currInd) =>
        index === currInd ? ({ ...w, [e.target.name]: parseNumber(w[e.target.name], 5) ?? null }) : w,
      ),
    }));
  // eslint-disable-next-line -- como setForm viene de un useState, no necesita incluirse aquí
  }, [ index ]);

  const accordionSummaryClass = dataOnWeb && finished ? classes.accordionOnWebFinished
    : finished ? classes.accordionFinished
    : classes.accordionSummary;

  const onCheckboxChange = useCallback(e => {
    setForm(pf => ({
      ...pf,
      wetland: pf.wetland.map((w, currInd) => index === currInd ? ({ ...w, [e.target.name]: e.target.checked }) : w),
    }));
  // eslint-disable-next-line
  }, [ index ]);

  useEffect(() => {
    const fetchWetlandOptions = async () => {
      if (wetland.wetlandCode) {
        const options = await protectedAreasApi.getWetlandOptions(wetland.wetlandCode);
        setWetlandOptions(options);
        const selected = options.find(opt => opt.cod === wetland.wetlandCode);
        setWetlandSelected(selected);
      }
    };
    fetchWetlandOptions();
  }, [ wetland ]);

  const getLabels = useCallback(value => value?.cod || wetlandOptions.find(opt => opt.cod === value)?.cod
    , [ wetlandOptions ]);

  const getWetlandOptions = async newInputValue => {
    try {
      const newOptions = await protectedAreasApi.getWetlandOptions(newInputValue);
      setWetlandOptions(newOptions);
      setIsFetchingOptions(false);
    } catch (err) {
      if (err.serverMessage) {
        toast.error(err.serverMessage);
      } else {
        console.error(`Problem getting the consultant names: ${err}`);
      }
    }
  };

  const getAndSetWetlands = generateDebouncer({ callback: getWetlandOptions, timeoutRef: getOptionsTimeoutRef, waitMs: 350 });

  const processChange = async value => {
    if (value?.trim()) {
      setIsFetchingOptions(true);
      getAndSetWetlands(value);
    } else {
      getAndSetWetlands.cancel();
      setIsFetchingOptions(false);
    }
  };

  return <Accordion TransitionProps={accordionTransitionPropObject}>
    <AccordionSummary className={ accordionSummaryClass } expandIcon={<ExpandMoreIcon />}>
      <Box display='flex' flexGrow={ 1 } alignItems='center' justifyContent='space-between'>
        <Box display='flex' alignItems='center'>
          { Boolean(errors) &&
            <Box component="span" mr={1} color="error.main">
              ¡Problema al validar datos!
            </Box>
          }
          <Typography>
            Humedal #{index + 1}
          </Typography>
        </Box>
        <Box>
          <Box onClick={stopEventPropagation} display="inline" mr={4}>
            <FormControlLabel labelPlacement="start" label="¿Finalizado?" className={classes.checkLabel}
              control={ <Checkbox color="primary" checked={finished}
                onChange={onCheckboxChange} name='finished'
              />
              }
            />
          </Box>
          <Box onClick={stopEventPropagation} display="inline">
            <Button variant="contained" color="secondary" onClick={ toggleHighlight }>
              Des/marcar en mapa
            </Button>
          </Box>
        </Box>
      </Box>
    </AccordionSummary>
    <AccordionDetails className={classes.accordionDetails}>
      <Grid container direction="row" spacing={2}>

        <Grid item md={12} xs={12}>
          <TypographicInputLabel htmlFor={`${featId}-type`}>Código</TypographicInputLabel>
          <Autocomplete
            value={wetland.wetlandCode}
            name={'type'}
            options={wetlandOptions}
            getOptionLabel={getLabels}
            onInputChange={async (event, newInputValue) => {
              if (newInputValue) {
                processChange(newInputValue);
              }
            }}
            noOptionsText={isFetchingOptions ? 'Buscando humedales' : 'No se encontraron resultados para el código ingresado'}
            getOptionSelected={getOptionSelected}
            filterSelectedOptions
            renderInput={params => (
              <TextField
                {...params}
                id={`${featId}-type`}
                variant="outlined"
                size="small"
                placeholder={'Ingrese código para completar datos'}
                error={Boolean(errors?.wetlandCode)}
                helperText={errors?.wetlandCode?.errorMessage}
              />
            )}
            onChange={(event, newValue) => {
              onChange({ target: { name: 'wetlandCode', value: newValue === null ? null : newValue.cod } });
              setWetlandSelected(newValue);
            }}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <TypographicInputLabel htmlFor={`${featId}-name`}>Nombre</TypographicInputLabel>
          <TextField value={wetlandSelected?.name || 'Sin información'} name="name" id={`${featId}-name`}
            size="small" fullWidth autoComplete="off" disabled/>
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-orden-1`}>Orden 1</TypographicInputLabel>
          <TextField value={wetlandSelected?.orden1 || 'Sin información'} name="orden1" id={`${featId}-orden-1`}
            size="small" fullWidth autoComplete="off" disabled/>
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-orden-2`}>Orden 2</TypographicInputLabel>
          <TextField value={wetlandSelected?.orden2 || 'Sin información'} name="orden1" id={`${featId}-orden-2`}
            size="small" fullWidth autoComplete="off" disabled/>
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-orden-3`}>Orden 3</TypographicInputLabel>
          <TextField value={wetlandSelected?.orden3 || 'Sin información'} name="orden3" id={`${featId}-orden-3`}
            size="small" fullWidth autoComplete="off" disabled/>
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-orden-4`}>Orden 4</TypographicInputLabel>
          <TextField value={wetlandSelected?.orden4 || 'Sin información'} name="orden4" id={`${featId}-orden-4`}
            size="small" fullWidth autoComplete="off" disabled/>
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-area-ha`}>Área del polígono [ha]</TypographicInputLabel>
          <TextField
            variant="outlined" size="small" autoComplete="off"
            name={'areaHa'}
            value={wetland.areaHa !== null ? wetland.areaHa : ''}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            onChange={e => testDecimalsFormat(e.target.value, { decimals: 5, wholePart: 10 }) && onChange(e)}
            error={Boolean(errors?.areaHa)}
            helperText={<>
              <span>Área en ha, con hasta 5 decimales.</span>{wetland.areaHa !== null ?
                <span>Valor en m2: {wetland.areaHa * 10000}</span> : null}
            </>}
            onBlur={onBlurNumber}
            fullWidth
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <TypographicInputLabel htmlFor={`${featId}-locality`}>Localidad</TypographicInputLabel>
          <TextField
            id={`${featId}-locality`}
            className={classes.textArea}
            minRows={1}
            maxRows={4}
            multiline
            name="locality"
            onChange={onChange}
            type="text"
            value={wetland.locality || ''}
            variant="outlined"
          />
        </Grid>


      </Grid>
    </AccordionDetails>
  </Accordion>;
});

WetlandForm.propTypes = {
  setHighlightedFeat: PropTypes.func,
  errors: PropTypes.object,
  index: PropTypes.number,
  setForm: PropTypes.func,
  wetland: PropTypes.object,
};

WetlandForm.displayName = 'WetlandForm';


export { WetlandForm };