import { get, put } from 'src/services/api';


const saveForm = async data => await put('/protected-areas', data);
const getData = async seaProjectId => (await get(`/protected-areas/data/${seaProjectId}`)).data;
const getWetlandOptions = async code => (await get(`/protected-areas/wetland/${code}`)).data;


export {
  saveForm,
  getData,
  getWetlandOptions,
};