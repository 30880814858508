import React, { memo, useCallback } from 'react';
import { Grid, Button, Typography, Box, Accordion, AccordionSummary, AccordionDetails, TextField,
  FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';

import { testDecimalsFormat, parseNumber } from 'src/utils';
import { TypographicInputLabel } from 'src/components';
import * as commons from 'src/scenes/subformCommons/subformCommons';


const useStyles = makeStyles(theme => ({
  ...commons.styles(theme),
  textArea: {
    width: '100%',
  },
}));

const getOptionSelected = (option, value) => option.id === value || option === value;

const accordionTransitionPropObject = { unmountOnExit: true };

const NOCAttractionsForm = memo(({ setHighlightedFeat, errors, index, setForm, nocAttraction, hierarchyOptions,
  ncaCategoriesOptions, ncaTypesOptions }) => {
  const { featId, finished, dataOnWeb } = nocAttraction;

  const stopEventPropagation = event => event.stopPropagation();

  const toggleHighlight = useCallback(() => {
    setHighlightedFeat({ featId });
  }, [ setHighlightedFeat, featId ]);

  const classes = useStyles();

  const onChange = useCallback(e => {
    const fieldName = e.target.name;
    const data = e.target.value;
    setForm(pf => ({
      ...pf,
      naturalOrCulturalAttractions:
        pf.naturalOrCulturalAttractions.map((nca, currInd) => index === currInd ? ({ ...nca, [fieldName]: data }) : nca),
    }));
  // eslint-disable-next-line -- como setForm viene de un useState, no necesita incluirse aquí
  }, [ index ]);

  const getHierarchyLabels = useCallback(value => hierarchyOptions.labels[value?.id ?? value], [ hierarchyOptions ]);
  const getNcaCategoryLabels = useCallback(value => ncaCategoriesOptions.labels[value?.id ?? value], [ ncaCategoriesOptions ]);
  const getNcaTypesLabels = useCallback(value => ncaTypesOptions.labels[value?.id ?? value], [ ncaTypesOptions ]);

  const accordionSummaryClass = dataOnWeb && finished ? classes.accordionOnWebFinished
    : finished ? classes.accordionFinished
    : classes.accordionSummary;

  const onCheckboxChange = useCallback(e => {
    setForm(pf => ({
      ...pf,
      naturalOrCulturalAttractions:
        pf.naturalOrCulturalAttractions.map((nca, currInd) => index === currInd ? ({ ...nca, [e.target.name]: e.target.checked }) : nca),
    }));
  // eslint-disable-next-line
  }, [ index ]);

  const onBlurNumber = useCallback(e => {
    setForm(pf => ({
      ...pf,
      naturalOrCulturalAttractions: pf.naturalOrCulturalAttractions.map((nca, currInd) =>
        index === currInd ? ({ ...nca, [e.target.name]: parseNumber(nca[e.target.name], 0) ?? null }) : nca,
      ),
    }));
  // eslint-disable-next-line -- como setForm viene de un useState, no necesita incluirse aquí
  }, [ index ]);

  return <Accordion TransitionProps={accordionTransitionPropObject}>
    <AccordionSummary className={ accordionSummaryClass } expandIcon={<ExpandMoreIcon />}>
      <Box display='flex' flexGrow={ 1 } alignItems='center' justifyContent='space-between'>
        <Box display='flex' alignItems='center'>
          { Boolean(errors) &&
            <Box component="span" mr={1} color="error.main">
              ¡Problema al validar datos!
            </Box>
          }
          <Typography>
            Atractivos naturales o culturales #{index + 1}{`: ${nocAttraction.name || ''}`}
          </Typography>
        </Box>
        <Box>
          <Box onClick={stopEventPropagation} display="inline" mr={4}>
            <FormControlLabel labelPlacement="start" label="¿Finalizado?" className={classes.checkLabel}
              control={ <Checkbox color="primary" checked={finished}
                onChange={onCheckboxChange} name='finished'
              />
              }
            />
          </Box>
          <Box onClick={stopEventPropagation} display="inline">
            <Button variant="contained" color="secondary" onClick={ toggleHighlight }>
              Des/marcar en mapa
            </Button>
          </Box>
        </Box>
      </Box>
    </AccordionSummary>
    <AccordionDetails className={classes.accordionDetails}>
      <Grid container direction="row" spacing={2}>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-name`}>Nombre</TypographicInputLabel>
          <TextField value={nocAttraction.name || ''} name="name" id={`${featId}-name`} variant="outlined"
            size="small" onChange={onChange} fullWidth autoComplete="off" />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-id`}>ID</TypographicInputLabel>
          <TextField value={nocAttraction.idInProject} name="idInProject" id={`${featId}-id`} variant="outlined" size="small"
            onChange={onChange}
            fullWidth
            autoComplete="off" />
        </Grid>

        <Grid item md={12} xs={12}>
          <TypographicInputLabel htmlFor={`${featId}-hierarchy`}>Jerarquía</TypographicInputLabel>
          <Autocomplete
            value={nocAttraction.hierarchy}
            name={'hierarchy'}
            options={hierarchyOptions.options}
            getOptionLabel={getHierarchyLabels}
            getOptionSelected={getOptionSelected}
            filterSelectedOptions
            renderInput={params => (
              <TextField
                {...params}
                id={`${featId}-hierarchy`}
                variant="outlined"
                size="small"
                placeholder={'Jerarquía'}
                error={Boolean(errors?.hierarchy)}
                helperText={errors?.hierarchy?.errorMessage}
              />
            )}
            onChange={(event, newValue) => {
              onChange({ target: { name: 'hierarchy', value: newValue === null ? null : newValue.id } });
            }}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <TypographicInputLabel htmlFor={`${featId}-category`}>Categoría</TypographicInputLabel>
          <Autocomplete
            value={nocAttraction.category}
            name={'category'}
            options={ncaCategoriesOptions.options}
            getOptionLabel={getNcaCategoryLabels}
            getOptionSelected={getOptionSelected}
            filterSelectedOptions
            renderInput={params => (
              <TextField
                {...params}
                id={`${featId}-category`}
                variant="outlined"
                size="small"
                placeholder={'Categoría'}
                error={Boolean(errors?.category)}
                helperText={errors?.category?.errorMessage}
              />
            )}
            onChange={(event, newValue) => {
              onChange({ target: { name: 'type', value: null } });
              onChange({ target: { name: 'category', value: newValue === null ? null : newValue.id } });
            }}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <TypographicInputLabel htmlFor={`${featId}-type`}>Tipo de atractivo</TypographicInputLabel>
          <Autocomplete
            value={nocAttraction.type}
            name={'type'}
            options={nocAttraction.category ? [
              ...ncaTypesOptions.options.filter(option => option.categoryId === nocAttraction.category),
              { id: 'Otros' },
            ] : []}
            getOptionLabel={getNcaTypesLabels}
            getOptionSelected={getOptionSelected}
            noOptionsText={'Seleccione una categoría para ver opciones'}
            filterSelectedOptions
            renderInput={params => (
              <TextField
                {...params}
                id={`${featId}-type`}
                variant="outlined"
                size="small"
                placeholder={'Tipo de atractivo'}
                error={Boolean(errors?.type)}
                helperText={errors?.type?.errorMessage}
              />
            )}
            onChange={(event, newValue) => {
              onChange({ target: { name: 'type', value: newValue === null ? null : newValue.id } });
            }}
          />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-dist-to-project`}>Distancia al proyecto [m]</TypographicInputLabel>
          <TextField
            variant="outlined" size="small" autoComplete="off"
            name={'distToProject'}
            value={nocAttraction.distToProject !== null ? nocAttraction.distToProject : ''}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            onChange={e => testDecimalsFormat(e.target.value, { decimals: 0 }) && onChange(e)}
            error={Boolean(errors?.distToProject)}
            helperText={errors?.distToProject ? errors.distToProject.errorMessage : ''}
            onBlur={onBlurNumber}
            fullWidth
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <TypographicInputLabel htmlFor={`${featId}-locality`}>Localidad</TypographicInputLabel>
          <TextField
            id={`${featId}-locality`}
            className={classes.textArea}
            minRows={1}
            maxRows={4}
            multiline
            name="locality"
            onChange={onChange}
            type="text"
            value={nocAttraction.locality || ''}
            variant="outlined"
          />
        </Grid>

      </Grid>
    </AccordionDetails>
  </Accordion>;
});

NOCAttractionsForm.propTypes = {
  setHighlightedFeat: PropTypes.func,
  errors: PropTypes.object,
  index: PropTypes.number,
  setForm: PropTypes.func,
  nocAttraction: PropTypes.object,
  hierarchyOptions: PropTypes.object,
  ncaCategoriesOptions: PropTypes.object,
  ncaTypesOptions: PropTypes.object,
};

NOCAttractionsForm.displayName = 'NOCAttractionsForm';


export { NOCAttractionsForm };