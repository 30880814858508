import { useCallback, useRef } from 'react';


const defaultLayerColor = '#3388ff';
const highlightedPolygonColor = 'rgba(32, 250, 250, 1)';
const highlightedPointClass = 'highlighted-point';

const highlightLayer = (layer, isPoint) => {
  if (isPoint) {
    // un solo punto
    if (layer._icon) {
      layer._icon.classList.add(highlightedPointClass);
    } else if (layer._layers) {
      Object.values(layer._layers).forEach(lyr => {
        lyr._icon.classList.add(highlightedPointClass);
      });
    }
    return;
  }
  // polígono:
  layer.setStyle({ color: highlightedPolygonColor });
};

const unhighlightLayer = (layer, isPoint) => {
  if (isPoint) {
    // un solo punto
    if (layer._icon) {
      layer._icon.classList.remove(highlightedPointClass);
    } else if (layer._layers) {
      Object.values(layer._layers).forEach(lyr => {
        lyr._icon.classList.remove(highlightedPointClass);
      });
    }
    return;
  }
  // polígono:
  layer.setStyle({ color: defaultLayerColor });
};

const useSetHighlightedFeat = ({ layersRef }) => {
  const highlightedFeat = useRef(null);

  const setHighlightedFeat = useCallback(({ featId, unhighlightPrevious = true, toggleSelf = true }) => {
    const prevHighlighted = highlightedFeat.current;
    const prevSameAsFeatId = prevHighlighted?.index === featId;
    if (prevHighlighted != null && ((toggleSelf && prevSameAsFeatId) || (unhighlightPrevious && !prevSameAsFeatId))) {
      unhighlightLayer(layersRef.current[prevHighlighted.index], prevHighlighted.isPoint);
    }
    if (featId === null || (prevSameAsFeatId && toggleSelf)) {
      highlightedFeat.current = null;
      return;
    }
    const layer = layersRef.current[featId];
    const isPoint = layer.feature.geometry.type === 'Point' ||
      layer.feature.geometry.type === 'MultiPoint';
    highlightLayer(layer, isPoint);
    highlightedFeat.current = { index: featId, isPoint };
  }, [ layersRef, highlightedFeat ]);

  return { setHighlightedFeat };
};


export { useSetHighlightedFeat };