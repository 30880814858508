import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { mdiLayersPlus, mdiTractor, mdiPickaxe, mdiShield } from '@mdi/js';


const LayersPlus = props => <SvgIcon {...props}><path d={mdiLayersPlus} /></SvgIcon>;
const Tractor = props => <SvgIcon {...props}><path d={mdiTractor} /></SvgIcon>;
const Pickaxe = props => <SvgIcon {...props}><path d={mdiPickaxe} /></SvgIcon>;
const Shield = props => <SvgIcon {...props}><path d={mdiShield} /></SvgIcon>;


export {
  LayersPlus,
  Tractor,
  Pickaxe,
  Shield,
};
