import yup from 'src/scenes/yup';


const apAndSpSchema = {
  name: yup.string().nullable(),
  idInProject: yup.string().nullable(),
  locality: yup.string().nullable(),
  areaHa: yup.number().min(0).nullable(),
  areaAi: yup.number().min(0).nullable(),
  distToProject: yup.number().min(0).nullable(),
};

const naturalOrCulturalAttractionsSchema = {
  name: yup.string().nullable(),
  idInProject: yup.string().nullable(),
  distToProject: yup.number().min(0).nullable(),
  locality: yup.string().nullable(),
};

const heritageSchema = {
  name: yup.string().nullable(),
  idInProject: yup.string().nullable(),
  isDeclared: yup.boolean().nullable(),
  description: yup.string().nullable(),
};

const rcaSchema = {
  name: yup.string().nullable(),
  idInProject: yup.string().nullable(),
  projectType: yup.string().nullable(),
  rcaDate: yup.string().nullable(),
  rcaNumber: yup.string().nullable(),
  distToProject: yup.number().min(0).nullable(),
};

const wetlandSchema = {
  name: yup.string().nullable(),
  idInProject: yup.string().nullable(),
  areaHa: yup.number().min(0).nullable(),
  locality: yup.string().nullable(),
};

const protectedAreasSchema = yup.object({
  geoJson: yup.object()
    .when('badData', { is: true,
      then: schema => schema.nullable(),
      otherwise: schema => schema.required('Si no puedes dar geometrías, marca la casilla de datos erróneos o no disponibles'),
    }),
  apAndSp: yup.array().of(yup.lazy(ap => !ap.finished ? yup.object() : yup.object().shape(apAndSpSchema))),
  naturalOrCulturalAttractions:
    yup.array().of(yup.lazy(nca => !nca.finished ? yup.object() : yup.object().shape(naturalOrCulturalAttractionsSchema))),
  heritage: yup.array().of(yup.lazy(h => !h.finished ? yup.object() : yup.object().shape(heritageSchema))),
  rca: yup.array().of(yup.lazy(r => !r.finished ? yup.object() : yup.object().shape(rcaSchema))),
  wetland: yup.array().of(yup.lazy(w => !w.finished ? yup.object() : yup.object().shape(wetlandSchema))),
  badData: yup.boolean(),
  comments: yup.string(),
});


export const arraySchemaIds = [ 'apAndSp', 'naturalOrCulturalAttractions', 'heritage', 'rca', 'wetland' ];

export default protectedAreasSchema;
