import React, { memo, useCallback } from 'react';
import { Grid, Button, Typography, Box, Accordion, AccordionSummary, AccordionDetails, TextField,
  FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';

import { testDecimalsFormat, parseNumber } from 'src/utils';
import { TypographicInputLabel } from 'src/components';
import * as commons from 'src/scenes/subformCommons/subformCommons';


const useStyles = makeStyles(theme => ({
  ...commons.styles(theme),
  textArea: {
    width: '100%',
  },
}));

const getOptionSelected = (option, value) => option.id === value || option === value;

const accordionTransitionPropObject = { unmountOnExit: true };

const ApAndSpForm = memo(({ setHighlightedFeat, errors, index, setForm, apAndSp, categoryOptions }) => {
  const { featId, finished, dataOnWeb } = apAndSp;

  const stopEventPropagation = event => event.stopPropagation();

  const toggleHighlight = useCallback(() => {
    setHighlightedFeat({ featId });
  }, [ setHighlightedFeat, featId ]);

  const classes = useStyles();

  const getCategoryLabels = useCallback(value => categoryOptions.labels[value?.id ?? value], [ categoryOptions ]);

  const onChange = useCallback(e => {
    const fieldName = e.target.name;
    const data = e.target.value;
    setForm(pf => ({
      ...pf,
      apAndSp:
        pf.apAndSp.map((aas, currInd) => index === currInd ? ({ ...aas, [fieldName]: data }) : aas),
    }));
  // eslint-disable-next-line -- como setForm viene de un useState, no necesita incluirse aquí
  }, [ index ]);

  const accordionSummaryClass = dataOnWeb && finished ? classes.accordionOnWebFinished
    : finished ? classes.accordionFinished
    : classes.accordionSummary;

  const onCheckboxChange = useCallback(e => {
    setForm(pf => ({
      ...pf,
      apAndSp:
        pf.apAndSp.map((aas, currInd) => index === currInd ? ({ ...aas, [e.target.name]: e.target.checked }) : aas),
    }));
  // eslint-disable-next-line
  }, [ index ]);

  const onBlurNumber = useCallback(e => {
    setForm(pf => ({
      ...pf,
      apAndSp: pf.apAndSp.map((aas, currInd) =>
        index === currInd ? ({ ...aas, [e.target.name]: parseNumber(aas[e.target.name], 5) ?? null }) : aas,
      ),
    }));
  // eslint-disable-next-line -- como setForm viene de un useState, no necesita incluirse aquí
  }, [ index ]);

  return <Accordion TransitionProps={accordionTransitionPropObject}>
    <AccordionSummary className={ accordionSummaryClass } expandIcon={<ExpandMoreIcon />}>
      <Box display='flex' flexGrow={ 1 } alignItems='center' justifyContent='space-between'>
        <Box display='flex' alignItems='center'>
          { Boolean(errors) &&
            <Box component="span" mr={1} color="error.main">
              ¡Problema al validar datos!
            </Box>
          }
          <Typography>
            Áreas protegidas y sitios prioritarios #{index + 1}{`: ${apAndSp.name || ''}`}
          </Typography>
        </Box>
        <Box>
          <Box onClick={stopEventPropagation} display="inline" mr={4}>
            <FormControlLabel labelPlacement="start" label="¿Finalizado?" className={classes.checkLabel}
              control={ <Checkbox color="primary" checked={finished}
                onChange={onCheckboxChange} name='finished'
              />
              }
            />
          </Box>
          <Box onClick={stopEventPropagation} display="inline">
            <Button variant="contained" color="secondary" onClick={ toggleHighlight }>
              Des/marcar en mapa
            </Button>
          </Box>
        </Box>
      </Box>
    </AccordionSummary>
    <AccordionDetails className={classes.accordionDetails}>
      <Grid container direction="row" spacing={2}>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-name`}>Nombre</TypographicInputLabel>
          <TextField value={apAndSp.name || ''} name="name" id={`${featId}-name`} variant="outlined"
            size="small" onChange={onChange} fullWidth autoComplete="off" />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-id`}>ID</TypographicInputLabel>
          <TextField value={apAndSp.idInProject} name="idInProject" id={`${featId}-id`} variant="outlined" size="small"
            onChange={onChange}
            fullWidth
            autoComplete="off" />
        </Grid>

        <Grid item md={12} xs={12}>
          <TypographicInputLabel htmlFor={`${featId}-category`}>Categoría</TypographicInputLabel>
          <Autocomplete
            value={apAndSp.category}
            name={'category'}
            options={categoryOptions.options}
            getOptionLabel={getCategoryLabels}
            getOptionSelected={getOptionSelected}
            filterSelectedOptions
            renderInput={params => (
              <TextField
                {...params}
                id={`${featId}-category`}
                variant="outlined"
                size="small"
                placeholder={'Categoría'}
                error={Boolean(errors?.category)}
                helperText={errors?.category?.errorMessage}
              />
            )}
            onChange={(event, newValue) => {
              onChange({ target: { name: 'category', value: newValue === null ? null : newValue.id } });
            }}
          />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-dist-to-project`}>Distancia al proyecto [m]</TypographicInputLabel>
          <TextField
            variant="outlined" size="small" autoComplete="off"
            name={'distToProject'}
            value={apAndSp.distToProject !== null ? apAndSp.distToProject : ''}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            onChange={e => testDecimalsFormat(e.target.value, { decimals: 0 }) && onChange(e)}
            error={Boolean(errors?.distToProject)}
            helperText={errors?.distToProject ? errors.distToProject.errorMessage : ''}
            onBlur={onBlurNumber}
            fullWidth
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <TypographicInputLabel htmlFor={`${featId}-locality`}>Localidad</TypographicInputLabel>
          <TextField
            id={`${featId}-locality`}
            className={classes.textArea}
            minRows={1}
            maxRows={4}
            multiline
            name="locality"
            onChange={onChange}
            type="text"
            value={apAndSp.locality}
            variant="outlined"
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <TypographicInputLabel htmlFor={`${featId}-area-ha`}>Área del polígono [ha]</TypographicInputLabel>
          <TextField
            variant="outlined" size="small" autoComplete="off"
            name={'areaHa'}
            value={apAndSp.areaHa !== null ? apAndSp.areaHa : ''}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            onChange={e => testDecimalsFormat(e.target.value, { decimals: 5, wholePart: 10 }) && onChange(e)}
            error={Boolean(errors?.areaHa)}
            helperText={<>
              <span>Área en ha, con hasta 5 decimales.</span>{apAndSp.areaHa !== null ?
                <span>Valor en m2: {apAndSp.areaHa * 10000}</span> : null}
            </>}
            onBlur={onBlurNumber}
            fullWidth
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <TypographicInputLabel htmlFor={`${featId}-area-ai`}>
            Área del polígono [ha] que intercepta con el área de influencia de esta componente
          </TypographicInputLabel>
          <TextField
            variant="outlined" size="small" autoComplete="off"
            name={'areaAi'}
            value={apAndSp.areaAi !== null ? apAndSp.areaAi : ''}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            onChange={e => testDecimalsFormat(e.target.value, { decimals: 5 }) && onChange(e)}
            error={Boolean(errors?.areaAi)}
            helperText={<>
              <span>Área en ha</span>{apAndSp.areaAi !== null ? <span>Valor en m2: {apAndSp.areaAi * 10000}</span> : null}
            </>}
            onBlur={onBlurNumber}
            fullWidth
          />
        </Grid>

      </Grid>
    </AccordionDetails>
  </Accordion>;
});

ApAndSpForm.propTypes = {
  setHighlightedFeat: PropTypes.func,
  errors: PropTypes.object,
  index: PropTypes.number,
  setForm: PropTypes.func,
  apAndSp: PropTypes.object,
  categoryOptions: PropTypes.object.isRequired,
};

ApAndSpForm.displayName = 'ApAndSpForm';


export { ApAndSpForm };