import React, { memo, useCallback } from 'react';
import { Grid, Button, Typography, Box, Accordion, AccordionSummary, AccordionDetails, TextField,
  FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';

import { testDecimalsFormat, parseNumber } from 'src/utils';
import { TypographicInputLabel } from 'src/components';
import * as commons from 'src/scenes/subformCommons/subformCommons';


const useStyles = makeStyles(theme => ({
  ...commons.styles(theme),
  textArea: {
    width: '100%',
  },
}));

const accordionTransitionPropObject = { unmountOnExit: true };

const ReceptorsForm = memo(({ setHighlightedFeat, errors, index, setForm, receptor }) => {
  const { featId, finished, dataOnWeb } = receptor;

  const environmentalComponentsOptions = [ 'Ruido', 'Vibraciones' ];

  const stopEventPropagation = event => event.stopPropagation();

  const toggleHighlight = useCallback(() => {
    setHighlightedFeat({ featId });
  }, [ setHighlightedFeat, featId ]);

  const classes = useStyles();

  const onChange = useCallback(e => {
    const fieldName = e.target.name;
    const data = e.target.value;
    setForm(pf => ({
      ...pf,
      receptors: pf.receptors.map((r, currInd) => index === currInd ? ({ ...r, [fieldName]: data }) : r),
    }));
  // eslint-disable-next-line -- como setForm viene de un useState, no necesita incluirse aquí
  }, [ index ]);

  const onBlurNumber = useCallback(e => {
    setForm(pf => ({
      ...pf,
      receptors: pf.receptors.map((r, currInd) =>
        index === currInd ? ({ ...r, [e.target.name]: parseNumber(r[e.target.name], 0) ?? null }) : r,
      ),
    }));
  // eslint-disable-next-line -- como setForm viene de un useState, no necesita incluirse aquí
  }, [ index ]);

  const accordionSummaryClass = dataOnWeb && finished ? classes.accordionOnWebFinished
    : finished ? classes.accordionFinished
    : classes.accordionSummary;

  const onCheckboxChange = useCallback(e => {
    setForm(pf => ({
      ...pf,
      receptors: pf.receptors.map((cm, currInd) => index === currInd ? ({ ...cm, [e.target.name]: e.target.checked }) : cm),
    }));
  // eslint-disable-next-line
  }, [ index ]);

  return <Accordion TransitionProps={accordionTransitionPropObject}>
    <AccordionSummary className={ accordionSummaryClass } expandIcon={<ExpandMoreIcon />}>
      <Box display='flex' flexGrow={ 1 } alignItems='center' justifyContent='space-between'>
        <Box display='flex' alignItems='center'>
          { Boolean(errors) &&
            <Box component="span" mr={1} color="error.main">
              ¡Problema al validar datos!
            </Box>
          }
          <Typography>
            Receptor #{index + 1}{`: ${receptor.name || ''}`}
          </Typography>
        </Box>
        <Box>
          <Box onClick={stopEventPropagation} display="inline" mr={4}>
            <FormControlLabel labelPlacement="start" label="¿Finalizado?" className={classes.checkLabel}
              control={ <Checkbox color="primary" checked={finished}
                onChange={onCheckboxChange} name='finished'
              />
              }
            />
          </Box>
          <Box onClick={stopEventPropagation} display="inline">
            <Button variant="contained" color="secondary" onClick={ toggleHighlight }>
              Des/marcar en mapa
            </Button>
          </Box>
        </Box>
      </Box>
    </AccordionSummary>
    <AccordionDetails className={classes.accordionDetails}>
      <Grid container direction="row" spacing={2}>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-name`}>Nombre</TypographicInputLabel>
          <TextField value={receptor.name || ''} name="name" id={`${featId}-name`} variant="outlined"
            size="small" onChange={onChange} fullWidth autoComplete="off" />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-dist-to-project`}>Distancia al proyecto [m]</TypographicInputLabel>
          <TextField
            variant="outlined" size="small" autoComplete="off"
            name={'distToProject'}
            value={receptor.distToProject !== null ? receptor.distToProject : ''}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            onChange={e => testDecimalsFormat(e.target.value, { decimals: 0 }) && onChange(e)}
            error={Boolean(errors?.distToProject)}
            helperText={errors?.distToProject ? errors.distToProject.errorMessage : ''}
            onBlur={onBlurNumber}
            fullWidth
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Box mb={1}>
            <TypographicInputLabel htmlFor={`${featId}-environmental-components`}>
              Componentes ambientales
            </TypographicInputLabel>
          </Box>
          <Box my={1}>
            <Autocomplete
              value={receptor.environmentalComponents}
              multiple
              name={'environmentalComponents'}
              options={environmentalComponentsOptions}
              getOptionLabel={value => value}
              filterSelectedOptions
              renderInput={params => (
                <TextField
                  {...params}
                  id={`${featId}-ambiental-components`}
                  variant="outlined"
                  placeholder={receptor.environmentalComponents.length > 0 ? '' : 'Componentes ambientales'}
                />
              )}
              onChange={(event, newValue) => {
                onChange({ target: { name: 'environmentalComponents', value: newValue === null ? null : newValue } });
              }}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box my={1}>
            <Box>
              <TypographicInputLabel htmlFor={`${featId}-method-description`}>Descripción</TypographicInputLabel>
              <TextField
                id={`${featId}-description`}
                className={classes.textArea}
                minRows={1}
                maxRows={4}
                multiline
                name="description"
                onChange={onChange}
                helperText={`Breve descripción de los receptores`}
                type="text"
                value={receptor.description}
                variant="outlined"
              />
            </Box>
          </Box>
        </Grid>

      </Grid>
    </AccordionDetails>
  </Accordion>;
});

ReceptorsForm.propTypes = {
  setHighlightedFeat: PropTypes.func,
  errors: PropTypes.object,
  index: PropTypes.number,
  setForm: PropTypes.func,
  receptor: PropTypes.object,
};

ReceptorsForm.displayName = 'ReceptorsForm';


export { ReceptorsForm };